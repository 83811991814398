/* eslint-disable import/prefer-default-export */
import { watchEffect, Ref, ref, watch } from '@vue/composition-api';
import { useUserState } from '@/store';
import axios from 'axios';

export function useSetupOrganizations() {
  const invitedOrgs: any = ref([]);
  const isFetching = ref(false);
  const { user }: any = useUserState(['user']);

  const getOrganizationsInvitations = async () => {
    try {
      isFetching.value = true;
      const data = {
        invitee: user.value?.email || user.value?.phoneNumber
      };

      const res = await axios.post(`${process.env.VUE_APP_SPONSOR_SERVICE}/invited-orgs`, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        }
      });

      if (res.data) {
        invitedOrgs.value = res.data.data;
        isFetching.value = false;
      }
      return res.data.data;
    } catch (error) {
      console.log(error);
      isFetching.value = false;
      return error;
    }
  };

  watchEffect(() => {
    if (user.value) {
      getOrganizationsInvitations();
    }
  });

  return {
    invitedOrgs,
    getOrganizationsInvitations,
    isFetching
  };
}

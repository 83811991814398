



































































































































import { reactive, toRefs, ref, Ref, onMounted, defineComponent } from '@vue/composition-api';
import { useUserGetters, useDbActions, useUserActions } from '@/store';
import { ActionTypes } from '@/store/modules/db/actions';
import { GetterTypes } from '@/store/modules/auth/getters';
import { SchoolPortfolio } from '@/generated/graphql';
import { ObjectId } from 'bson';
import Loading from '@/components/Loading.vue';
import { SCHOOL_ROLE, STATE } from '@/constants/portfolio';
import { THIS_SCHOOL } from '@/services/graphql/queries';

export default defineComponent({
  name: 'SchoolID',
  components: {
    Loading
  },
  setup(_props, { emit }) {
    const schoolStaffType = reactive({ schoolrole: SCHOOL_ROLE, stateOpts: STATE });
    const details: any = reactive({
      staffType: [],
      position: '',
      schoolDistrict: '',
      district: {
        streetAddress: '',
        city: '',
        state: '',
        zipcode: ''
      }
    });
    const { getObjectId } = useUserGetters([GetterTypes.getObjectId]);
    const { updatePortfolio, getUserPermissions } = useUserActions([
      'updatePortfolio',
      'getUserPermissions'
    ]);
    const { getUserPortfolios } = useUserActions(['getUserPortfolios']);
    const loader: Ref<any> = ref(null);

    onMounted(() => {
      loader.value!.process();
    });

    async function processQuery() {
      // return query({
      //   query: THIS_SCHOOL,
      //   variables: { id: getObjectId.value }
      // }).then(({ data: { schoolPortfolio: res } }) => {
      const portfoliosData = await getUserPortfolios({ user_id: getObjectId.value?.toString() });
      if (portfoliosData.School) {
        const res = portfoliosData.School;
        details.value = res;
        Object.keys(details).forEach(key => {
          if (res[key]) details[key] = res[key];
        });
      }
      // });
    }

    async function save() {
      const res = await updatePortfolio({
        type: 'SchoolPortfolio',
        payload: {
          _id: getObjectId.value,
          staffType: details.staffType,
          position: details.position,
          schoolDistrict: details.schoolDistrict,
          district: details.district
        }
      });
      if (!res?.response?.data?.error) {
        const response = await getUserPermissions();
        if (response) {
          localStorage.setItem('permissions', response?.permissions);
        }
      }
      emit('input', 'School');
    }

    return { save, ...toRefs(schoolStaffType), ...toRefs(details), processQuery, loader };
  }
});

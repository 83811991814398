





















































































































import { reactive, toRefs, ref, Ref, onMounted, defineComponent } from '@vue/composition-api';

import { useUserGetters, useDbActions, useUserActions } from '@/store';
import { GetterTypes } from '@/store/modules/auth/getters';
import Loading from '@/components/Loading.vue';
import { STATE } from '@/constants/portfolio';

export default defineComponent({
  name: 'TeacherID',
  components: {
    Loading
  },
  setup(_props, { emit }) {
    const { getObjectId } = useUserGetters([GetterTypes.getObjectId]);
    const { updatePortfolio, getUserPermissions } = useUserActions([
      'updatePortfolio',
      'getUserPermissions'
    ]);
    const { getUserPortfolios } = useUserActions(['getUserPortfolios']);
    const formOpt = reactive({ stateOpts: STATE });
    const details: any = reactive({
      schoolDistrict: '',
      schoolName: '',
      subjects: [] as string[],
      schools: {
        streetAddress: '',
        city: '',
        state: '',
        zipcode: ''
      }
    });

    const loader: Ref<any> = ref(null);

    function remove(item: string) {
      details.subjects.splice(details.subjects.indexOf(item), 1);
      details.subjects = [...details.subjects];
    }
    onMounted(() => {
      loader.value!.process();
    });

    async function processQuery() {
      const portfoliosData = await getUserPortfolios({ user_id: getObjectId.value?.toString() });
      if (portfoliosData.Teacher) {
        const res = portfoliosData.Teacher;
        details.value = res;
        Object.keys(details).forEach(key => {
          if (res[key]) details[key] = res[key];
        });
      }
      // });
    }

    async function save() {
      const res = await updatePortfolio({
        type: 'TeacherPortfolio',
        payload: {
          _id: getObjectId.value,
          schoolDistrict: details.schoolDistrict,
          schoolName: details.schoolName,
          subjects: details.subjects,
          schools: details.schools
        }
      });
      if (!res?.response?.data?.error) {
        const response = await getUserPermissions();
        if (response) {
          localStorage.setItem('permissions', response?.permissions);
        }
      }
      emit('input', 'Teacher');
    }

    return {
      ...toRefs(details),
      ...toRefs(formOpt),
      save,
      processQuery,
      loader,
      remove
    };
  }
});

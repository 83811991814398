




































































































import { reactive, toRefs, ref, Ref, onMounted, defineComponent } from '@vue/composition-api';
import { useUserGetters, useDbActions, useUserState, useUserActions } from '@/store';
import { ActionTypes } from '@/store/modules/db/actions';
import { GetterTypes } from '@/store/modules/auth/getters';
import { SendReferalInput, ParentPortfolio } from '@/generated/graphql';
import Loading from '@/components/Loading.vue';
import axios from 'axios';
import { STATE, RE_SKILL } from '@/constants/portfolio';
import { THIS_PARENT } from '@/services/graphql/queries';
import { SEND_PARTICIPANTS_REFFERAL } from '@/services/graphql/mutations';
import AAutocomplete from '@/components/atoms/AAutocomplete.vue';

const { getObjectId } = useUserGetters([GetterTypes.getObjectId]);
const { updatePortfolio, getUserPermissions } = useUserActions([
  'updatePortfolio',
  'getUserPermissions'
]);
const { getUserPortfolios } = useUserActions(['getUserPortfolios']);
export default defineComponent({
  name: 'ParentID',
  components: {
    Loading,
    AAutocomplete
  },
  setup(
    props,
    {
      emit,
      root: {
        $apolloProvider: {
          defaultClient: { query, mutate }
        }
      }
    }
  ) {
    const formOpt = reactive({ stateOpts: STATE, reSkillItems: RE_SKILL });
    const details: any = reactive({
      parent: {
        streetAddress: '',
        city: '',
        state: '',
        zipcode: ''
      },
      email: '',
      currentEmployer: '',
      positionTitle: '',
      reSkill: '',
      linkedIn: ''
    });
    const isSearchingStreet = ref(false);
    const loader: Ref<any> = ref(null);
    const emailSent = ref(false);
    onMounted(() => {
      loader.value!.process();
    });
    async function processQuery() {
      // return query<{ parentPortfolio: ParentPortfolio }>({
      //   query: THIS_PARENT,
      //   variables: { id: getObjectId.value }
      // }).then(({ data: { parentPortfolio: res } }) => {
      //   console.log(res);
      const portfoliosData = await getUserPortfolios({ user_id: getObjectId.value?.toString() });
      if (portfoliosData.Parent) {
        const res = portfoliosData.Parent;
        details.value = res;
        Object.keys(details).forEach(key => {
          if (res[key]) details[key] = res[key];
        });
      }
      // });
    }
    const { user } = useUserState(['user']);
    function sendInvite() {
      return mutate({
        mutation: SEND_PARTICIPANTS_REFFERAL,
        variables: {
          email: details.email,
          id: getObjectId.value,
          name: `${user.value!.firstName} ${user.value!.lastName}`
        } as SendReferalInput
      }).then(() => {
        emailSent.value = true;
      });
    }

    async function save() {
      const res = await updatePortfolio({
        type: 'ParentPortfolio',
        payload: {
          _id: getObjectId.value,
          parent: details.parent,
          email: details.email,
          currentEmployer: details.currentEmployer,
          positionTitle: details.positionTitle,
          linkedIn: details.linkedIn,
          reSkill: details.reSkill
        }
      });
      if (!res?.response?.data?.error) {
        const response = await getUserPermissions();
        if (response) {
          localStorage.setItem('permissions', response?.permissions);
        }
      }
      emit('input', 'Parent');
    }

    async function fetchPlaces(val) {
      try {
        isSearchingStreet.value = true;
        const resp = await axios.get(`${process.env.VUE_APP_GOOGLE_PLACES_DETAILS}`, {
          params: {
            place_id: val.place_id
          },
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (resp.data.data.result && resp.data.data.result.address_components) {
          details.parent.state = resp.data.data.result.address_components.find(
            itm => itm.types[0] === 'administrative_area_level_1'
          ).short_name;
          details.parent.zipcode = resp.data.data.result.address_components.find(
            itm => itm.types[0] === 'postal_code'
          ).long_name;
          details.parent.city = resp.data.data.result.address_components.find(
            itm => itm.types[0] === 'locality'
          ).long_name;
        }
        isSearchingStreet.value = false;
      } catch (err) {
        console.log(err);
        isSearchingStreet.value = false;
      }
    }

    function setStreetValue(e) {
      if (e.target) {
        details.parent.streetAddress = e.target.value;
      } else if (e.name) {
        details.parent.streetAddress = e.formatted_address;
        fetchPlaces(e);
      }
    }

    return {
      ...toRefs(details),
      ...toRefs(formOpt),
      save,
      processQuery,
      loader,
      sendInvite,
      emailSent,
      isSearchingStreet,
      setStreetValue
    };
  }
});

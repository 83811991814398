






















































































































import {
  Ref,
  reactive,
  toRefs,
  ref,
  onMounted,
  defineComponent,
  watch,
  computed
} from '@vue/composition-api';

import { useUserGetters, useDbActions, useUserActions } from '@/store';
import { GetterTypes } from '@/store/modules/auth/getters';
import { EmployerPortfolio } from '@/generated/graphql';
import Loading from '@/components/Loading.vue';
import { STATE } from '@/constants/portfolio';
import { THIS_EMPLOYER } from '@/services/graphql/queries';
import axios from 'axios';
import _ from 'lodash';
import AAutocomplete from '@/components/atoms/AAutocomplete.vue';

export default defineComponent({
  name: 'EmployerID',

  components: {
    Loading,
    AAutocomplete
  },

  setup(_props, { emit }) {
    const { getObjectId } = useUserGetters([GetterTypes.getObjectId]);
    const { updatePortfolio, getUserPermissions } = useUserActions([
      'updatePortfolio',
      'getUserPermissions'
    ]);
    const { getUserPortfolios } = useUserActions(['getUserPortfolios']);
    const search = ref('');
    const streetSearch = ref('');
    const streetList = ref([{ id: 1, name: 'select' }]);
    const isSearchingStreet = ref(false);
    const selectedStreet = reactive({
      id: 1,
      name: ''
    });
    const formOpt = reactive({
      stateOpts: STATE
    });
    const employer: any = reactive({
      employerName: '',
      jobTitle: '',
      industry: '',
      primaryProduct: '',
      work: {
        streetAddress: '',
        city: '',
        state: '',
        zipcode: ''
      }
    });

    const loader: Ref<any> = ref(null);

    async function fetchPlaces(val) {
      try {
        isSearchingStreet.value = true;
        const resp = await axios.get(`${process.env.VUE_APP_GOOGLE_PLACES_DETAILS}`, {
          params: {
            place_id: val.place_id
          },
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (resp.data.data.result && resp.data.data.result.address_components) {
          employer.work.state = resp.data.data.result.address_components.find(
            itm => itm.types[0] === 'administrative_area_level_1'
          ).short_name;
          employer.work.zipcode = resp.data.data.result.address_components.find(
            itm => itm.types[0] === 'postal_code'
          ).long_name;
          employer.work.city = resp.data.data.result.address_components.find(
            itm => itm.types[0] === 'locality'
          ).long_name;
        }
        isSearchingStreet.value = false;
      } catch (err) {
        console.log(err);
        isSearchingStreet.value = false;
      }
    }

    function setStreetValue(e) {
      if (e.target) {
        employer.work.streetAddress = e.target.value;
      } else if (e.name) {
        employer.work.streetAddress = e.name;
        fetchPlaces(e);
      }
    }

    onMounted(() => {
      loader.value!.process();
    });

    async function processQuery() {
      // return query<{ employerPortfolio: EmployerPortfolio }>({
      //   query: THIS_EMPLOYER,
      //   variables: { id: getObjectId.value }
      // }).then(({ data: { employerPortfolio: res } }) => {
      //   if (res)
      const portfoliosData = await getUserPortfolios({ user_id: getObjectId.value?.toString() });
      if (portfoliosData.Employer) {
        const res = portfoliosData.Employer;
        employer.value = res;
        Object.keys(employer).forEach(key => {
          if (res[key]) employer[key] = res[key];
        });
      }
      // });
    }

    async function save() {
      const res = await updatePortfolio({
        type: 'EmployerPortfolio',
        payload: {
          _id: getObjectId.value,
          employerName: employer.employerName,
          jobTitle: employer.jobTitle,
          industry: employer.industry,
          primaryProduct: employer.primaryProduct,
          work: employer.work
        }
      });
      if (!res?.response?.data?.error) {
        const response = await getUserPermissions();
        if (response) {
          localStorage.setItem('permissions', response?.permissions);
        }
      }
      emit('input', 'Employer');
    }

    return {
      ...toRefs(formOpt),
      ...toRefs(employer),
      save,
      processQuery,
      loader,
      streetList,
      isSearchingStreet,
      selectedStreet,
      fetchPlaces,
      setStreetValue,
      streetSearch
    };
  }
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('Loading',{ref:"loader",staticClass:"my-id__content",attrs:{"callback":_vm.processQuery},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_c('div',{staticClass:"my-id__wrapper"},[_c('v-skeleton-loader',{attrs:{"loading":loading,"type":"heading, list-item-two-line, list-item-two-line, list-item-three-line"}},[_c('div',{staticClass:"my-id__title mb-3"},[_vm._v("Student ID")]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"type":"heading, list-item-two-line, list-item-two-line, list-item-three-line","items":_vm.gradeLevel,"label":"Grade Level","outlined":""},model:{value:(_vm.grade),callback:function ($$v) {_vm.grade=$$v},expression:"grade"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('a-autocomplete',{staticStyle:{"margin-bottom":"25px"},attrs:{"error-message":errors,"placeholder":"Search School Name","type":"school","hint":"ie: San Leandro High"},on:{"val":_vm.setSchoolValue},model:{value:(_vm.school.name),callback:function ($$v) {_vm.$set(_vm.school, "name", $$v)},expression:"school.name"}})]}}],null,true)}),_c('validation-provider',{staticStyle:{"position":"relative"},attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('a-autocomplete',{staticStyle:{"margin-bottom":"25px"},attrs:{"error-message":errors,"placeholder":"Search School District","type":"school_district","hint":"ie: San Leandro Unified"},on:{"val":_vm.setDistrictValue},model:{value:(_vm.school.district),callback:function ($$v) {_vm.$set(_vm.school, "district", $$v)},expression:"school.district"}})]}}],null,true)}),_c('v-menu',{ref:"menu",attrs:{"value":false,"transition":"scale-transition","close-on-content-click":false,"offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"error-messages":errors,"label":"Birthdate","placeholder":"1988-10-25","hint":"YYYY-MM-DD","persistent-hint":"","outlined":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true)},[_c('v-date-picker',{ref:"picker",attrs:{"offset-y":"","max":new Date().toISOString().substr(0, 10),"min":"1950-01-01"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.menu.isActive = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.menu.isActive = false}}},[_vm._v(" OK ")])],1)],1),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.superGender,"error-messages":errors,"label":"Gender","outlined":""},model:{value:(_vm.gender),callback:function ($$v) {_vm.gender=$$v},expression:"gender"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.ethnicityCulture,"error-messages":errors,"multiple":"","label":"Ethnicity & Culture","outlined":""},model:{value:(_vm.ethnicity),callback:function ($$v) {_vm.ethnicity=$$v},expression:"ethnicity"}})]}}],null,true)}),_c('div',{staticClass:"d-flex flex-row my-id--position"},[_c('validation-provider',{staticClass:"mr-2",attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Guardian Email","outlined":""},model:{value:(_vm.guardian.email),callback:function ($$v) {_vm.$set(_vm.guardian, "email", $$v)},expression:"guardian.email"}})]}}],null,true)}),_c('Loading',{attrs:{"callback":_vm.sendInvite},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loadInvite = ref.loading;
var process = ref.process;
return [_c('v-btn',{staticClass:"my-id__button--append",attrs:{"depressed":"","outlined":"","x-large":"","loading":loadInvite},on:{"click":process}},[_vm._v("Invite")])]}}],null,true)})],1),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Guardian First Name","outlined":""},model:{value:(_vm.guardian.firstName),callback:function ($$v) {_vm.$set(_vm.guardian, "firstName", $$v)},expression:"guardian.firstName"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Guardian Last Name","outlined":""},model:{value:(_vm.guardian.lastName),callback:function ($$v) {_vm.$set(_vm.guardian, "lastName", $$v)},expression:"guardian.lastName"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.guardianRelationship,"error-messages":errors,"label":"Relationship to Guardian","outlined":""},model:{value:(_vm.guardian.relationship),callback:function ($$v) {_vm.$set(_vm.guardian, "relationship", $$v)},expression:"guardian.relationship"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticStyle:{"display":"none"},attrs:{"error-messages":errors,"label":"City","outlined":"","disabled":""},model:{value:(_vm.home.city),callback:function ($$v) {_vm.$set(_vm.home, "city", $$v)},expression:"home.city"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticStyle:{"display":"none"},attrs:{"error-messages":errors,"label":"State","outlined":"","disabled":""},model:{value:(_vm.home.state),callback:function ($$v) {_vm.$set(_vm.home, "state", $$v)},expression:"home.state"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#####'),expression:"'#####'"}],staticStyle:{"display":"none"},attrs:{"error-messages":errors,"label":"Zipcode","outlined":"","maxlength":"5","disabled":""},model:{value:(_vm.home.zipcode),callback:function ($$v) {_vm.$set(_vm.home, "zipcode", $$v)},expression:"home.zipcode"}})]}}],null,true)})],1),_c('Loading',{attrs:{"callback":_vm.save},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var saving = ref.loading;
var save = ref.process;
return [_c('v-btn',{attrs:{"disabled":invalid && _vm.emailSent,"loading":saving,"dark":!invalid,"block":"","large":"","depressed":""},on:{"click":save}},[_vm._v("Save")])]}}],null,true)}),_c('div',{staticClass:"d-flex justify-center mt-5"},[(_vm.alert)?_c('v-alert',{staticClass:"green white--text"},[_vm._v("User profile information saved successfully")]):_vm._e()],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }








































































































































import {
  Ref,
  reactive,
  ref,
  toRefs,
  onMounted,
  defineComponent,
  computed,
  toRef,
  watchEffect
} from '@vue/composition-api';
import { useUserGetters, useUserActions, useUserState } from '@/store';
import axios from 'axios';
import Loading from '@/components/Loading.vue';
import { GetterTypes } from '@/store/modules/auth/getters';
import { CITIZEN_TYPES } from '@/constants/portfolio';
import ATeleInput from '@/components/atoms/ATeleInput.vue';
// commenting out mobile component
import { addStakeholderToOrg } from '@/api/organizationApi';
import { useSetupOrganizations } from '../../composables/useSetupOrganizations';

export default defineComponent({
  name: 'GeneralID',
  components: {
    Loading,
    ATeleInput
  },
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  setup(_props, { emit }) {
    const { getObjectId } = useUserGetters([GetterTypes.getObjectId]);
    const { getUserByKeyAndValue } = useUserActions(['getUserByKeyAndValue']);
    const { fetchUserById, fetchUser } = useUserActions(['fetchUserById', 'fetchUser']);
    const { updatePortfolio, getUserPermissions } = useUserActions([
      'updatePortfolio',
      'getUserPermissions'
    ]);

    // Page Setup
    const AVAILABLE_IDS = ref(CITIZEN_TYPES);
    const show = ref(false);
    const showPassword = ref(false);
    const user: any = reactive({
      firstName: '',
      lastName: '',
      phoneNumber: '',
      formattedNumber: '',
      email: '',
      userTypes: [],
      confirm_email: ''
    });
    const isEmailAvailable = ref(false);
    const showConfirmEmail = ref(false);
    const isPhoneAvailable = ref(false);
    const loader: Ref<any> = ref(null);
    const isOpenDialog = ref(false);
    const isPhoneValid = ref(false);
    const isSetting = ref(false);
    const passwordValue = ref('');
    const isOrgAvailable = ref(false);
    const isChecked = ref(false);
    const msg = ref('');
    const isGuardian = ref(false);

    if (localStorage.getItem('is-guardian')) {
      isGuardian.value = true;
      const obj = { text: 'Parent', value: 'Parent' };
      user.userTypes.push(obj.value);
    }

    const { invitedOrgs, isFetching } = useSetupOrganizations();

    const selectedOrganizations: any = ref();

    const availableOrganizations = computed(() => {
      return invitedOrgs.value.map(x => {
        return {
          _id: x._id?.toString(),
          name: x.name
        };
      });
    });

    watchEffect(() => {
      if (availableOrganizations.value.length > 0) {
        selectedOrganizations.value = availableOrganizations.value;
      }
    });

    const isValid = computed(() => {
      if (passwordValue.value.length >= 6 && isPhoneAvailable.value) {
        return true;
      }
      if (isEmailAvailable.value) {
        return true;
      }
      return false;
    });

    const validEmail = computed(() => {
      if (user.email === user.confirm_email && isPhoneAvailable.value) {
        return true;
      }
      if (isEmailAvailable.value) {
        return true;
      }
      return false;
    });

    function setPasswordValue(val) {
      passwordValue.value = val;
      isOpenDialog.value = false;
      isChecked.value = true;
    }

    function getValidation(val) {
      if (val && val.countryCallingCode && val.formatted && val.valid) {
        user.formattedNumber = `+${val.countryCallingCode}${val.formatted}`;
      } else {
        user.formattedNumber = '';
      }
      isPhoneValid.value = val.valid;
    }

    const phoneValid = computed(() => {
      if (isPhoneValid.value && user.phoneNumber) {
        return true;
      }
      return false;
    });

    const isPhoneCheck = computed(() => {
      const substring = '+1';
      const substring1 = '+';

      if (user.phoneNumber.includes(substring) || user.phoneNumber.includes(substring1)) {
        return false;
      }

      return true;
    });

    async function processQuery() {
      const response = await fetchUserById({
        user_id: getObjectId.value?.toString()
      });
      const userRes = response.data;
      user.value = userRes;
      if (userRes.email) {
        isEmailAvailable.value = true;
      }
      if (userRes.phoneNumber) {
        isPhoneAvailable.value = true;
      }
      Object.keys(user).forEach(key => {
        if (userRes && userRes[key]) user[key] = userRes[key];
        if (userRes && userRes.phoneNumber) user.phoneNumber = userRes.phoneNumber.substring(2);
      });
    }

    async function setPassword() {
      try {
        isSetting.value = true;
        const data = {
          type: 'link_email',
          email: user.email,
          password: passwordValue.value
        };
        const res = await axios.post(`${process.env.VUE_APP_AUTH0}/add-identity`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        });
        if (res.data) {
          emit('input', user.userTypes);
        }
        isSetting.value = false;
      } catch (error: any) {
        console.log(error);
        msg.value = error?.response?.data?.error?.description;
        isSetting.value = false;
      }
    }

    async function setMobile() {
      try {
        isSetting.value = true;
        const data = {
          type: 'link_phone',
          phone_number: user.formattedNumber
        };
        const res = await axios.post(`${process.env.VUE_APP_AUTH0}/add-identity`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        });

        if (res.data) {
          emit('input', user.userTypes);
        }
        isSetting.value = false;
      } catch (error: any) {
        console.log(error);
        msg.value = error?.response?.data?.error?.description;
        isSetting.value = false;
      }
    }

    async function removeGuardianFlag() {
      try {
        const API_ENDPOINT = process.env.VUE_APP_STUDENT_SERVICE;

        const res = await axios.patch(
          `${API_ENDPOINT}/update/agreement-flag?student_id=${localStorage.getItem('is-guardian')}`
        );

        if (res) {
          localStorage.removeItem('is-guardian');
        }
      } catch (error) {
        console.log(error);
      }
    }

    async function save() {
      if (localStorage.getItem('is-guardian')) {
        await removeGuardianFlag();
      }
      msg.value = '';
      let validateRecord = null;
      if (isPhoneAvailable.value && !isEmailAvailable.value) {
        validateRecord = await getUserByKeyAndValue({
          key: 'email',
          value: encodeURIComponent(user.email)
        });
      } else if (isEmailAvailable.value && user.phoneNumber && !isPhoneAvailable.value) {
        validateRecord = await getUserByKeyAndValue({
          key: 'phoneNumber',
          value: encodeURIComponent(user.formattedNumber)
        });
      }
      if (!validateRecord) {
        const updateUserResponse = await updatePortfolio({
          type: 'User',
          payload: {
            _id: getObjectId.value,
            firstName: user.firstName,
            lastName: user.lastName,
            phoneNumber: user.formattedNumber,
            userTypes: user.userTypes,
            email: user.email.toLowerCase()
          }
        });

        if (!updateUserResponse?.response?.data?.error) {
          const response = await getUserPermissions();
          if (response) {
            localStorage.setItem('permissions', response?.permissions);
          }
          if (isPhoneAvailable.value && !isEmailAvailable.value) {
            setPassword();
          } else if (isEmailAvailable.value && user.phoneNumber && !isPhoneAvailable.value) {
            setMobile();
          } else {
            emit('input', user.userTypes);
          }

          if (invitedOrgs.value.length > 0 && selectedOrganizations.value.length > 0) {
            const data = {
              user_id: getObjectId.value.toString(),
              organization_ids: selectedOrganizations.value.map(x => x._id),
              role: 'admin'
            };
            await addStakeholderToOrg(data);
          }
          await fetchUser(localStorage.getItem('apollo-token'));
        } else {
          msg.value = 'Portfolio not updated successfully';
        }
      } else {
        msg.value = 'Email or Phone already exists';
      }
    }
    onMounted(() => {
      loader.value!.process();
    });

    return {
      AVAILABLE_IDS,
      removeGuardianFlag,
      ...toRefs(user),
      save,
      isFetching,
      processQuery,
      loader,
      isEmailAvailable,
      isPhoneAvailable,
      isOpenDialog,
      isSetting,
      setPassword,
      setPasswordValue,
      isOrgAvailable,
      passwordValue,
      setMobile,
      isValid,
      isChecked,
      msg,
      getValidation,
      validEmail,
      showConfirmEmail,
      isPhoneValid,
      phoneValid,
      show,
      showPassword,
      isPhoneCheck,
      isGuardian,
      availableOrganizations,
      selectedOrganizations,
      invitedOrgs
    };
  }
});

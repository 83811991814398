












































































































import { Ref, reactive, toRefs, ref, onMounted, defineComponent } from '@vue/composition-api';

import { useUserGetters, useUserActions } from '@/store';
import { GetterTypes } from '@/store/modules/auth/getters';
import Loading from '@/components/Loading.vue';
import { STATE } from '@/constants/portfolio';
import axios from 'axios';
import _ from 'lodash';
import AAutocomplete from '@/components/atoms/AAutocomplete.vue';

export default defineComponent({
  name: 'OrganizerID',

  components: {
    Loading,
    AAutocomplete
  },

  setup(
    _props,
    {
      emit,
      root: {
        $apolloProvider: {
          defaultClient: { query }
        }
      }
    }
  ) {
    const { getObjectId } = useUserGetters([GetterTypes.getObjectId]);
    const { updatePortfolio, getUserPermissions } = useUserActions([
      'updatePortfolio',
      'getUserPermissions'
    ]);
    const { getUserPortfolios } = useUserActions(['getUserPortfolios']);
    const search = ref('');
    const streetSearch = ref('');
    const streetList = ref([{ id: 1, name: 'select' }]);
    const isSearchingStreet = ref(false);
    const selectedStreet = reactive({
      id: 1,
      name: ''
    });
    const formOpt = reactive({
      stateOpts: STATE
    });
    const organizer: any = reactive({
      organizerName: '',
      jobTitle: '',
      industry: '',
      primaryProduct: '',
      work: {
        streetAddress: '',
        city: '',
        state: '',
        zipcode: ''
      }
    });

    const loader: Ref<any> = ref(null);

    async function fetchPlaces(val) {
      try {
        isSearchingStreet.value = true;
        const resp = await axios.get(`${process.env.VUE_APP_GOOGLE_PLACES_DETAILS}`, {
          params: {
            place_id: val.place_id
          },
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (resp.data.data.result && resp.data.data.result.address_components) {
          organizer.work.state = resp.data.data.result.address_components.find(
            itm => itm.types[0] === 'administrative_area_level_1'
          ).short_name;
          organizer.work.zipcode = resp.data.data.result.address_components.find(
            itm => itm.types[0] === 'postal_code'
          ).long_name;
          organizer.work.city = resp.data.data.result.address_components.find(
            itm => itm.types[0] === 'locality'
          ).long_name;
        }
        isSearchingStreet.value = false;
      } catch (err) {
        console.log(err);
        isSearchingStreet.value = false;
      }
    }

    function setStreetValue(e) {
      if (e.target) {
        organizer.work.streetAddress = e.target.value;
      } else if (e.name) {
        organizer.work.streetAddress = e.name;
        fetchPlaces(e);
      }
    }

    onMounted(() => {
      loader.value!.process();
    });

    async function processQuery() {
      // return query<{ organizerPortfolio: OrganizerPortfolio }>({
      //   query: THIS_ORGANIZER,
      //   variables: { id: getObjectId.value }
      // }).then(({ data: { organizerPortfolio: res } }) => {
      //   if (res)
      const portfoliosData = await getUserPortfolios({ user_id: getObjectId.value?.toString() });
      if (portfoliosData.Organizer) {
        const res = portfoliosData.Organizer;
        organizer.value = res;
        Object.keys(organizer).forEach(key => {
          if (res[key]) organizer[key] = res[key];
        });
      }
      // });
    }

    async function save() {
      const res = await updatePortfolio({
        type: 'OrganizerPortfolio',
        payload: {
          _id: getObjectId.value,
          organizerName: organizer.organizerName,
          jobTitle: organizer.jobTitle,
          industry: organizer.industry,
          primaryProduct: organizer.primaryProduct,
          work: organizer.work
        }
      });
      if (!res?.response?.data?.error) {
        const response = await getUserPermissions();
        if (response) {
          localStorage.setItem('permissions', response?.permissions);
        }
      }
      emit('input');
    }

    return {
      ...toRefs(formOpt),
      ...toRefs(organizer),
      save,
      processQuery,
      loader,
      streetList,
      isSearchingStreet,
      selectedStreet,
      fetchPlaces,
      setStreetValue,
      streetSearch
    };
  }
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('Loading',{ref:"loader",staticClass:"my-id__content",attrs:{"callback":_vm.processQuery},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_c('div',{staticClass:"my-id__wrapper"},[_c('v-skeleton-loader',{attrs:{"loading":loading,"type":"heading, list-item-two-line, list-item-two-line, list-item-three-line"}},[_c('div',{staticClass:"my-id__title mb-3"},[_vm._v("School ID")]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.schoolrole,"error-messages":errors,"multiple":"","label":"Staff Type","outlined":""},model:{value:(_vm.staffType),callback:function ($$v) {_vm.staffType=$$v},expression:"staffType"}})]}}],null,true)}),_c('v-text-field',{attrs:{"label":"Position Title","placeholder":"If Not Listed Above","outlined":""},model:{value:(_vm.position),callback:function ($$v) {_vm.position=$$v},expression:"position"}}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"School or School District","outlined":""},model:{value:(_vm.schoolDistrict),callback:function ($$v) {_vm.schoolDistrict=$$v},expression:"schoolDistrict"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"School or School District Address","outlined":""},model:{value:(_vm.district.streetAddress),callback:function ($$v) {_vm.$set(_vm.district, "streetAddress", $$v)},expression:"district.streetAddress"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"City","outlined":""},model:{value:(_vm.district.city),callback:function ($$v) {_vm.$set(_vm.district, "city", $$v)},expression:"district.city"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.stateOpts,"label":"State","outlined":""},model:{value:(_vm.district.state),callback:function ($$v) {_vm.$set(_vm.district, "state", $$v)},expression:"district.state"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#####'),expression:"'#####'"}],attrs:{"error-messages":errors,"label":"Zipcode","outlined":"","maxlength":"5"},model:{value:(_vm.district.zipcode),callback:function ($$v) {_vm.$set(_vm.district, "zipcode", $$v)},expression:"district.zipcode"}})]}}],null,true)})],1),_c('Loading',{attrs:{"callback":_vm.save},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var saving = ref.loading;
var save = ref.process;
return [_c('v-btn',{attrs:{"disabled":invalid,"loading":saving,"dark":!invalid,"block":"","large":"","depressed":""},on:{"click":save}},[_vm._v(" Save ")])]}}],null,true)})],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }